<template>
  <v-card
    :height="getHeight(0)"
    width="100%"
    :img="require('@/assets/HowToPlayBackgroundBigSize.png')"
    dark
    class="d-flex align-end"
    :class="[isMobile ? 'align-end pb-3' : 'align-center']"
  >
    <v-btn
      :class="[`${isMobile ? '' : 'ml-4'}`]"
      text
      fixed
      x-small
      fab
      top
      left
      @click="closeBtn"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-card
      style="overflow: scroll"
      :height="isMobile ? '92%' : '90%'"
      :width="isMobile ? '90%' : '60%'"
      color="#1a1a1a"
      id="maPisPeElDeCard"
      elevation="24"
      class="d-flex flex-column pa-4 mx-auto scroll noScroll"
    >
      <p
        class="text-center text-h5 font-weight-bold poppinsText mt-2 mb-2"
        :style="{ fontSize: `${displaySize(1, 0.04)}em` }"
        style="position: sticky; position: -webkit-stickys"
      >
        How to Play
      </p>
      <p class="regularText text-h6 text-center">- Basic Rules of the Game -</p>

      <div
        :style="{ fontSize: `${displaySize(1, 0.04)}em`, width: '90%' }"
        class="regularText align-self-center mt-1"
      >
        <p>
          &#9672; Mastery Case is an online prize-winning mystery game that
          contains several uncommon puzzle cases with hidden messages and
          secrets, that eventually lead to a Secret Code.
        </p>
        <p>
          &#9672; The first user to figure out the Secret Code of an unsolved
          puzzle will get the prize.
        </p>
        <p>
          &#9672; For each puzzle, hints may become available. They will guide
          you to discover the Secret Code.
        </p>
        <p>
          &#9672; Once a puzzle becomes solved, you can unlock the solution of
          the puzzle, which is a video, that explains in detail the steps for
          discovering the Secret Code.
        </p>
        <p>
          &#9672; If you win any of our prizes, you will be contacted via your
          account’s e-mail for the bank account information (IBAN), in order to
          make the money transfer. We will never ask you for your card’s
          sensitive details! Also, you can provide us an unique comment of
          yours, that will be displayed in the Winners Section next to your
          username.
        </p>
        <p>
          &#9672; In the Newsletter section, you can see all the announcements
          made for the game. You can also subscribe to our e-mails from the
          Account Information section, to be notified every time an event
          happens.
        </p>
        <p>
          &#9672; Keep in mind, this is not a usual game, so think outside the
          box!
        </p>
        <p>&#9672; Good luck and have fun!</p>
      </div>
    </v-card>
  </v-card>
</template>

<script>
export default {
  methods: {
    closeBtn() {
      if (this.$store.getters["previousRoute"].name !== null)
        this.$router.go(-1);
      else this.$router.push({ name: "main" });
    },
  },
  created() {
    this.$store.commit("currentPage", { page: "howToPlay" });
  },
};
</script>

<style scoped>
#maPisPeElDeCard {
  border-radius: 22px;
  filter: drop-shadow(0 0 0.5rem #9b9b9b);
}
</style>
